<script setup lang="ts">
import type { NovaCreatorProfileCardTinyProps } from './NovaCreatorProfileCardTiny.types'
import { ROUTES } from '@configs'

const props = withDefaults(defineProps<NovaCreatorProfileCardTinyProps>(), {
  forceMobileLayout: false,
  onGoCreatorAfter: () => {},
})

const handleOnGoCreatorHome = () => {
  useNavigations({
    url: useRoutePathIdChange(ROUTES.CREATOR.path, {
      id: `@${props.creator.creatorLink}`,
    }),
  })
  props.onGoCreatorAfter()
}
</script>

<template>
  <a
    :class="[
      'nova-creator-profile-card',
      { 'force-mobile-layout': forceMobileLayout },
    ]"
    @click="handleOnGoCreatorHome"
  >
    <div class="portrait">
      <NovaImageContainer
        :ratio="'1:1'"
        :image-url="creator.creatorProfl || ''"
      />
    </div>
    <div class="profile-info">
      <h4 class="creator-name">{{ creator.creatorNcnm || '&nbsp;' }}</h4>
      <p class="category">{{ creator.ctgryNm || '&nbsp;' }}</p>
    </div>
  </a>
</template>

<style scoped lang="scss">
@mixin mobile-layout {
  border-radius: 10px;

  .profile-info {
    padding: 7px 10px 10px;

    .creator-name {
      color: #434343;
      font-size: 12px;
      letter-spacing: -0.3px;
    }

    .category {
      color: $color-neutral-dark-4;
      font-size: 10px;
      letter-spacing: -0.25px;
    }
  }
}

.nova-creator-profile-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 20px;
  background-color: $color-white;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;

  .portrait {
    background-color: $color-bg-custom-1;
  }

  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 16px 18px;

    .creator-name {
      color: $color-black;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 28px */
      letter-spacing: -0.5px;

      @include ellipsis(1);
    }

    .category {
      color: $color-text-12;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: -0.4px;
      margin-top: 3px;

      @include ellipsis(1);
    }
  }

  &.force-mobile-layout {
    @include mobile-layout;
  }

  @include mobile {
    @include mobile-layout;
  }
}
</style>
